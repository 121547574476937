exports.components = {
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-privacy-policy-jsx": () => import("./../../../src/pages/legal/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-jsx" */),
  "component---src-pages-legal-terms-jsx": () => import("./../../../src/pages/legal/terms.jsx" /* webpackChunkName: "component---src-pages-legal-terms-jsx" */),
  "component---src-pages-portfolio-index-jsx": () => import("./../../../src/pages/portfolio/index.jsx" /* webpackChunkName: "component---src-pages-portfolio-index-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-services-support-jsx": () => import("./../../../src/pages/services/support.jsx" /* webpackChunkName: "component---src-pages-services-support-jsx" */),
  "component---src-pages-services-web-development-jsx": () => import("./../../../src/pages/services/web-development.jsx" /* webpackChunkName: "component---src-pages-services-web-development-jsx" */),
  "component---src-templates-case-study-jsx": () => import("./../../../src/templates/case-study.jsx" /* webpackChunkName: "component---src-templates-case-study-jsx" */)
}

